import { useState } from 'react'

import { useEditPacSources } from '../hooks/useEditPacSources'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import { removeUnderscoreAndCapitalize } from '@/utils/formats'
import { SECURE_FIELD_MASK } from '@/utils/forms/optionConsts'

import { FormInputsT, PacsSourceInstanceI } from '../types/types'
import {
  ACS_KEY,
  INSTANCE_NAME_KEY,
  getPacsSourceInstanceFields,
  getPacsSourceSecureFieldIds,
} from '../utils/utils'
import { PACSCredentialsModalHeader } from './PACSCredentialsModalHeader'

interface EditPACSCredentialsModalIProps {
  isOpen: boolean
  onClose: () => void
  pacsSourceInstance: PacsSourceInstanceI
}

export const EditPACSCredentialsModal = ({
  isOpen,
  onClose,
  pacsSourceInstance,
}: EditPACSCredentialsModalIProps) => {
  const [secureCleanFieldIds, setSecureCleanFieldIds] = useState<string[]>(
    getPacsSourceSecureFieldIds(pacsSourceInstance)
  )
  const {
    handleSubmit,
    register,
    control,
    errors,
    formState: { isValid, dirtyFields },
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { update, isLoading } = useEditPacSources({
    pacsSourceInstance,
    onClose,
  })
  const onSubmit: SubmitHandler<FormInputsT> = async (values) => {
    const fields = getPacsSourceInstanceFields(dirtyFields, getValues)
    update(values.instanceName, fields)
  }

  const handleFieldUpdated = (fieldName: string, isSecure: boolean) => {
    if (isSecure) {
      const updatedIds = secureCleanFieldIds.filter(
        (secureFieldId) => secureFieldId !== fieldName
      )
      if (updatedIds.length !== secureCleanFieldIds.length) {
        setValue(fieldName, '')
        setSecureCleanFieldIds(updatedIds)
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <PACSCredentialsModalHeader
          subTitle='Update info to connected to an ACS'
          title='Update PACS Credentials'
        />
        <ModalBody p={0}>
          <Box px={6} py={2}>
            <Box mb={3}>
              <FormInputSelectControl
                control={control}
                defaultValue={{
                  label: pacsSourceInstance.sourceName,
                  value: pacsSourceInstance.sourceId,
                }}
                id={ACS_KEY}
                isDisabled={true}
                label='ACS*'
                placeholder='Select ACS type'
              />
            </Box>
            <Box mb={3}>
              <FormInputControl
                defaultValue={pacsSourceInstance.name}
                errorMessage={errors?.instanceName?.message}
                id={INSTANCE_NAME_KEY}
                inputRef={register({
                  required: 'Name is required',
                })}
                isInvalid={Boolean(errors?.instanceName)}
                label='Name'
                placeholder='Enter Name'
                type='text'
              />
            </Box>
            {pacsSourceInstance?.fields?.map((f) => {
              const name = removeUnderscoreAndCapitalize(
                f.pacsSourceField?.name
              )
              const isSecure = f.pacsSourceField?.secure
              return (
                <Box key={f.id} mb={3}>
                  <FormInputControl
                    defaultValue={isSecure ? SECURE_FIELD_MASK : f.value}
                    errorMessage={errors[f.id]?.message}
                    id={f.id}
                    inputRef={register({
                      required: `${name} is required`,
                    })}
                    isInvalid={Boolean(errors[f.id])}
                    label={`${name}*`}
                    onChange={() => handleFieldUpdated(f.id, isSecure)}
                    placeholder={`Enter ${name}`}
                    type={isSecure ? 'password' : 'text'}
                  />
                </Box>
              )
            })}
          </Box>
        </ModalBody>
        <ModalFooter pb={6} py={4}>
          <Button mr={3} onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            isDisabled={!isValid}
            isLoading={isLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Saving'
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
