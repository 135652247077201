import { Box, Circle, Flex, Skeleton } from '@chakra-ui/react'

export interface PageHeaderIProps {
  count: number
  label: string
}

export const CounterBadge = ({ count, label }: PageHeaderIProps) => {
  return (
    <Flex alignItems='center' paddingLeft='6'>
      <Box fontSize='20px' fontWeight='extrabold'>
        {label}
      </Box>
      {count >= 0 ? (
        <Circle
          bg='#14327A'
          color='white'
          fontSize='16px'
          fontWeight='bold'
          h='24px'
          ml='3'
          paddingX='9px'
        >
          {count}
        </Circle>
      ) : (
        <Skeleton h='24px' ml='3' rounded='full' w='24px' />
      )}
    </Flex>
  )
}
