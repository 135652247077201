import { useSelector } from 'react-redux'

import { AccordionPanel, Flex, Grid } from '@chakra-ui/react'

import { EditButton, SettingsRow } from '@/components/ui'
import { selectEditMode } from '@/redux/ui/uiSlice'
import { removeUnderscoreAndCapitalize } from '@/utils/formats'
import { SECURE_FIELD_MASK } from '@/utils/forms/optionConsts'

import { PacsSourceFieldInstanceI } from '../types/types'

interface PACSSourceFieldsIProps {
  fields: PacsSourceFieldInstanceI[]
  index: number
  onEdit: () => void
  pacsSourceInstanceName: string
}

export const PACSSourceInstance = ({
  fields,
  index,
  onEdit,
  pacsSourceInstanceName,
}: PACSSourceFieldsIProps) => {
  const isEditMode = useSelector(selectEditMode)

  return (
    <AccordionPanel
      bgColor={index % 2 === 0 ? 'transparent' : 'white'}
      borderTop='1px solid #eaedf3'
      p='0'
    >
      <Flex alignItems='center' justifyContent='space-between' px='4' py='6'>
        <Grid columnGap='6' gridTemplateColumns='auto 1fr' rowGap='4' w='full'>
          <SettingsRow
            key={pacsSourceInstanceName}
            label='Name'
            value={pacsSourceInstanceName}
          />
          {fields?.map((field) => (
            <SettingsRow
              key={field.id}
              label={removeUnderscoreAndCapitalize(field.pacsSourceField?.name)}
              value={
                field.pacsSourceField?.secure ? SECURE_FIELD_MASK : field.value
              }
            />
          ))}
        </Grid>
        {isEditMode && <EditButton handleOnEdit={onEdit} />}
      </Flex>
    </AccordionPanel>
  )
}
