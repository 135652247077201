import {
  Box,
  Icon,
  ModalCloseButton,
  ModalHeader,
  Spacer,
} from '@chakra-ui/react'

import { PACS } from '@/components/icons'

interface PACsModalHeaderIProps {
  title: string
  subTitle: string
}

export const PACSCredentialsModalHeader = ({
  title,
  subTitle,
}: PACsModalHeaderIProps) => {
  return (
    <ModalHeader>
      <Box align-items='center' display='flex' m='20px auto'>
        <Box display='grid' mt={1}>
          <Icon as={PACS} boxSize='26px' />
        </Box>
        <Box color='#353849' flexDirection='row' ml={2}>
          <Box fontSize='25px' fontWeight={700}>
            {title}
          </Box>

          <Box fontSize='16px' fontWeight={500}>
            {subTitle}
          </Box>
        </Box>
        <Spacer />
        <ModalCloseButton color='#000' opacity={0.4} position='initial' />
      </Box>
    </ModalHeader>
  )
}
