import { useToasts } from '@/hooks/useToasts'

import { useUpdatePacsSourceInstanceMutation } from '@/graphql/generated/hooks'
import {
  PacsSourceFieldInstanceInput,
  UpdatePacsSourceInstanceInput,
} from '@/graphql/generated/schemas'

import { PacsSourceInstanceI } from '../types/types'

interface UseEditPacSourcesIProps {
  pacsSourceInstance: PacsSourceInstanceI
  onClose: () => void
}

export const useEditPacSources = ({
  pacsSourceInstance,
  onClose,
}: UseEditPacSourcesIProps) => {
  const [updatePacsSource, { loading: isLoading }] =
    useUpdatePacsSourceInstanceMutation()
  const { showSuccess, showError } = useToasts()

  const update = async (
    name: string,
    fields: PacsSourceFieldInstanceInput[]
  ) => {
    const input: UpdatePacsSourceInstanceInput = {
      id: pacsSourceInstance.id,
      name,
      fields,
    }
    try {
      await updatePacsSource({
        variables: { input },
        refetchQueries: ['PacsSourceSecretsSettings'],
      })
      showSuccess(`Successfully updated ${name} PACS Creditionals`)
      onClose()
    } catch (e) {
      showError(e.message)
    }
  }

  return {
    isLoading,
    update,
  }
}
