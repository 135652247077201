import { Flex } from '@chakra-ui/react'

interface IProps {
  left?: React.ReactElement
  center?: React.ReactElement
  right?: React.ReactElement
  dataTestId?: string
}

export const Header = ({ left, center, right, dataTestId }: IProps) => {
  return (
    <Flex
      alignItems='center'
      bgColor='white'
      borderBottom='1px solid #D5DCE4'
      data-testid={dataTestId}
      h='65px'
      justifyContent='space-between'
      pos='relative'
      zIndex='10'
    >
      {left && <Flex flexGrow={1}>{left}</Flex>}
      {center && (
        <Flex flexGrow={1} justifyContent='center'>
          {center}
        </Flex>
      )}
      {right && (
        <Flex
          alignItems='center'
          flexGrow={1}
          h='full'
          justifyContent='flex-end'
        >
          {right}
        </Flex>
      )}
    </Flex>
  )
}
