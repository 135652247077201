import { useMemo } from 'react'

import { useToasts } from '@/hooks/useToasts'

import {
  useCreatePacsSourceInstanceMutation,
  usePacsSourcesQuery,
} from '@/graphql/generated/hooks'
import { CreatePacsSourceInstanceInput } from '@/graphql/generated/schemas'
import { getPacsOptions } from '@/utils/forms/optionBuilders'

import { FormInputsT } from '../types/types'
import { getPacsSourceFields } from '../utils/utils'

interface UseCreatePacsSourcesIProps {
  pacSourceId: string
  onClose: () => void
}

export const useCreatePacsSources = ({
  pacSourceId,
  onClose,
}: UseCreatePacsSourcesIProps) => {
  const { data, loading: isLoading } = usePacsSourcesQuery()
  const [createPacsSource] = useCreatePacsSourceInstanceMutation()
  const { showSuccess, showError } = useToasts()
  const pacsOptions = useMemo(() => getPacsOptions(data), [data])
  const pacsSources = useMemo(
    () => data?.pacsSources?.edges.map((e) => e.node),
    [data]
  )

  const pacsSource = useMemo(
    () => pacsSources?.find((s) => s.id === pacSourceId),
    [pacSourceId, pacsSources]
  )

  const create = async (inputs: FormInputsT) => {
    const input: CreatePacsSourceInstanceInput = {
      name: inputs.instanceName,
      pacsSourceId: inputs.acs?.value,
      fields: getPacsSourceFields(inputs),
    }

    try {
      await createPacsSource({
        variables: { input },
        refetchQueries: ['PacsSourceSecretsSettings'],
      })
      showSuccess(
        `Successfully created ${inputs.instanceName} PACS Creditionals`
      )
      onClose()
    } catch (e) {
      showError(e.message)
    }
  }

  return {
    create,
    pacsSource,
    pacsSources,
    pacsOptions,
    isLoading,
  }
}
