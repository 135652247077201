import { AlertDialogBody, AlertDialogFooter, Box } from '@chakra-ui/react'

import { BasicAlertDialog, Button } from '@/components/ui'

interface ConfirmEditModalIProps {
  isOpen: boolean
  onClose: () => void
  onConfirmClick: () => void
  title: string
}

export const ConfirmEditModal = ({
  isOpen,
  onClose,
  onConfirmClick,
  title,
}: ConfirmEditModalIProps) => {
  return (
    <BasicAlertDialog isOpen={isOpen} onClose={onClose} title={title}>
      <AlertDialogBody>
        <Box color='#353849' fontSize='14px' fontWeight={500}>
          When changing the password, it does not take effect until HiveWatch is
          notified and a re-deployment takes place. Please reach out to your
          HiveWatch representative to notify them of this change and apply it
        </Box>
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button onClick={onConfirmClick}>Confirm</Button>
      </AlertDialogFooter>
    </BasicAlertDialog>
  )
}
