import { AccordionItem } from '@chakra-ui/react'
import { AccordionButton, AccordionIcon, Box } from '@chakra-ui/react'

export const PACSSourceGroup = ({
  name,
  children,
}: {
  name: string
  children: React.ReactChild | React.ReactChild[]
}) => {
  return (
    <AccordionItem
      _hover={{
        boxShadow: '0px 2px 5px 6px rgba(0, 0, 0, 0.15)x',
      }}
      border='none'
      borderRadius='5px'
      boxShadow='0px 2px 6px 2px rgba(0, 0, 0, 0.1)'
      mb='6'
      overflow='hidden'
      p='0'
      transition='ease-in-out'
      transitionDuration='300ms'
      transitionProperty='all'
    >
      <h2 style={{ position: 'relative' }}>
        <AccordionButton
          _focus={{ boxShadow: 'none' }}
          bg='white'
          data-testid={`settingsPage_pacsSource_section_${name}GroupButton`}
          p='4'
        >
          <Box
            flex='1'
            fontSize='20px'
            fontWeight='extrabold'
            letterSpacing='-0.6px'
            textAlign='left'
          >
            {name}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      {children}
    </AccordionItem>
  )
}
