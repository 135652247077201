import { useState } from 'react'

import { Accordion, Box, Flex, Spinner, useDisclosure } from '@chakra-ui/react'

import { usePacsSourceSecretsSettingsQuery } from '@/graphql/generated/hooks'

import { PacsSourceInstanceI } from '../types/types'
import { getPacsSourceInstances } from '../utils/utils'
import { ConfirmEditModal } from './ConfirmEditModal'
import { EditPACSCredentialsModal } from './EditPACSCredentialsModal'
import { PACSSourceGroup } from './PACSSourceGroup'
import { PACSSourceInstance } from './PACSSourceInstance'
import { PACSSourceNotSet } from './PACSSourceNotSet'

export const PACSSourceSecretsSettings = () => {
  const { data, loading: isLoading } = usePacsSourceSecretsSettingsQuery({
    fetchPolicy: 'cache-and-network',
  })
  const pacsSourceInstances = getPacsSourceInstances(data)
  const [pacsSource, setPacsSource] = useState<PacsSourceInstanceI>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure()

  const onPACSSourceEdit = async (pacSource: PacsSourceInstanceI) => {
    setPacsSource(pacSource)
    onConfirmOpen()
  }
  return isLoading ? (
    <Flex justifyContent='center' mt='10'>
      <Spinner size='lg' />
    </Flex>
  ) : (
    <>
      <Box fontSize='24px' fontWeight='extrabold' letterSpacing='-0.8px' mb='4'>
        PACS Source Secrets
      </Box>
      <Accordion allowMultiple allowToggle>
        {pacsSourceInstances.map(({ id, name, instances }) => (
          <PACSSourceGroup key={id} name={name}>
            {instances?.length > 0 ? (
              instances.map((instance, i) => (
                <PACSSourceInstance
                  fields={instance.fields}
                  index={i}
                  key={i}
                  onEdit={() => onPACSSourceEdit(instance)}
                  pacsSourceInstanceName={instance.name}
                />
              ))
            ) : (
              <PACSSourceNotSet />
            )}
          </PACSSourceGroup>
        ))}
      </Accordion>
      {isConfirmOpen && (
        <ConfirmEditModal
          isOpen={isConfirmOpen}
          onClose={onConfirmClose}
          onConfirmClick={() => {
            onConfirmClose()
            onOpen()
          }}
          title='Update PACS Credentials'
        />
      )}

      {isOpen && pacsSource && (
        <EditPACSCredentialsModal
          isOpen={isOpen}
          onClose={onClose}
          pacsSourceInstance={pacsSource}
        />
      )}
    </>
  )
}
