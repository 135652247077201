import { useEffect, useState } from 'react'

import { useCreatePacsSources } from '../hooks/useCreatePacsSources'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import { removeUnderscoreAndCapitalize } from '@/utils/formats'

import { FormInputsT } from '../types/types'
import { ACS_KEY, INSTANCE_NAME_KEY } from '../utils/utils'
import { PACSCredentialsModalHeader } from './PACSCredentialsModalHeader'

interface CreatePACSModalIProps {
  isOpen: boolean
  onClose: () => void
}

export const CreatePACSCredentialsModal = ({
  isOpen,
  onClose,
}: CreatePACSModalIProps) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    formState,
    reset,
    errors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const [isDirty, setIsDirty] = useState(false)
  const accessControlOption = watch(ACS_KEY)
  const { isLoading, pacsOptions, pacsSource, create } = useCreatePacsSources({
    pacSourceId: getValues(ACS_KEY)?.value,
    onClose,
  })

  const onSubmit: SubmitHandler<FormInputsT> = async (values) => {
    create(values)
  }

  useEffect(() => {
    reset({ acs: getValues(ACS_KEY) })
    setIsDirty(false)
  }, [accessControlOption])

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <PACSCredentialsModalHeader
          subTitle='Enter info to connect to an ACS'
          title='Add PACS Credentials'
        />
        <ModalBody p={0}>
          <Box px={6} py={2}>
            <form>
              <Box mb={3}>
                <FormInputSelectControl
                  control={control}
                  defaultValue={null}
                  id={ACS_KEY}
                  inputRef={register({
                    required: `ACS is required`,
                  })}
                  isDisabled={isLoading}
                  label='ACS*'
                  options={pacsOptions}
                  placeholder='Select ACS type'
                  rules={{ required: true }}
                />
              </Box>
              {pacsSource && (
                <Box mb={3}>
                  <FormInputControl
                    errorMessage={errors?.instanceName?.message}
                    id={INSTANCE_NAME_KEY}
                    inputRef={register({
                      required: 'Name is required',
                    })}
                    isInvalid={Boolean(errors?.instanceName)}
                    label='Name'
                    placeholder='Enter Name'
                    type='text'
                  />
                </Box>
              )}
              {pacsSource?.fields?.map((p) => {
                const name = removeUnderscoreAndCapitalize(p.name)
                return (
                  <Box key={p.id} mb={3}>
                    <FormInputControl
                      errorMessage={errors[p.id]?.message}
                      id={p.id}
                      inputRef={register({
                        required: `${name} is required`,
                      })}
                      isInvalid={Boolean(errors[p.id])}
                      label={`${name}*`}
                      onChange={() => setIsDirty(true)}
                      placeholder={`Enter ${name}`}
                      type={p.secure ? 'password' : 'text'}
                    />
                  </Box>
                )
              })}
            </form>
          </Box>
        </ModalBody>
        <ModalFooter pb={6} py={4}>
          <Button mr={3} onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            isDisabled={!formState.isValid || !isDirty}
            isLoading={false}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Saving'
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
